@font-face {
font-family: '__bagoss_4a634d';
src: url(/_next/static/media/ddfbc1a62491d7ef-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__bagoss_4a634d';
src: url(/_next/static/media/a02ff5fd641983e1-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__bagoss_4a634d';
src: url(/_next/static/media/b634d7b1dc163f21-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__bagoss_4a634d';
src: url(/_next/static/media/a0a255dd6693611e-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__bagoss_4a634d';
src: url(/_next/static/media/d77160b16d51dffb-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__bagoss_4a634d';
src: url(/_next/static/media/2830875c71b7ff0a-s.p.woff) format('woff');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__bagoss_Fallback_4a634d';src: local("Arial");ascent-override: 90.89%;descent-override: 20.83%;line-gap-override: 0.00%;size-adjust: 100.34%
}.__className_4a634d {font-family: '__bagoss_4a634d', '__bagoss_Fallback_4a634d'
}.__variable_4a634d {--bagoss-standard: '__bagoss_4a634d', '__bagoss_Fallback_4a634d'
}

